<template>
  <div class="interface">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link>
      <span> > {{ $t('System Configuration')}} > </span>
      <span style="color: #3461ff">{{ $t("Interface Configuration") }}</span>
    </div>
    <topbar>
      <div class="handleBtn">
        <div>
          <el-button type="primary" size="small" @click="newadd">
            <i class="el-icon-plus" />
            {{ $t("button.New") }}
          </el-button>
          <el-button
            type="danger"
            size="small"
            :disabled="!selection()"
            @click="alldelt"
          >
            <i class="el-icon-delete" />
            {{ $t("button.Batch Delete") }}
          </el-button>
        </div>
      </div>
    </topbar>
    <div class="table_set card">
      <div class="storelistrom">
        <span>{{ $t("templateInfo.Records") }}:{{ leng }}</span>
      </div>
      <el-table
        ref="tableRows"
        :data="tableData"
        style="width: 100%"
        class="table-topstyle"
        @select-all="handleSelectAll"
        @sort-change="handleSortChange"
        table-layout="fixed"
        max-height="500"
      >
        <el-table-column type="selection" width="55">
          <el-table-column type="selection" />
        </el-table-column>
        <el-table-column
          :label="$t('interfaceConfig.Interface Name')"
          sortable="custom"
          prop="interfaceName"
          min-width="180"
          header-align="center"
        >
          <el-table-column prop="interfaceName" min-width="180" align="center">
            <template slot="header">
              <el-input
                v-model="pageParams.interfaceName"
                size="mini"
                @input="getLegalList"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('interfaceConfig.Interface Type')"
          min-width="180"
          header-align="center"
        >
          <el-table-column
            prop="interfaceTypeDesc"
            min-width="180"
            align="center"
          >
            <template slot="header">
              <el-select
                v-model="pageParams.interfaceType"
                size="small"
                clearable
                @change="getLegalList"
                placeholder=""
              >
                <el-option
                  v-for="dict in interfaceTypeList"
                  :key="dict.codeId"
                  :label="dict.codeName"
                  :value="dict.codeId"
                />
              </el-select>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('interfaceConfig.States')"
          min-width="180"
          header-align="center"
        >
          <el-table-column prop="status" min-width="180" align="center">
            <template slot="header" slot-scope="scope">
              <el-select
                v-model="pageParams.status"
                size="small"
                clearable
                @change="getLegalList"
              >
                <el-option
                  v-for="dict in stateList"
                  :key="dict.dictValue"
                  :label="dict.dictName"
                  :value="dict.dictValue"
                />
              </el-select>
            </template>
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="stateChange(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('interfaceConfig.Update Time')"
          sortable="custom"
          prop="updateTime"
          min-width="220"
          header-align="center"
        >
          <el-table-column prop="updateTime" min-width="220" align="center">
            <template slot="header">
              <el-date-picker
                v-model="pageParams.time"
                type="daterange"
                align="center"
                size="small"
                unlink-panels
                range-separator="-"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @change="getLegalList"
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('interfaceConfig.Action')"
          width="120"
          fixed="right"
        >
          <el-table-column width="120">
            <template slot="header" />
            <template slot-scope="scope">
              <!-- <feather-icon
                class="mr-1"
                icon="Trash2Icon"
                style="margin-right:0;color:#3B4660"
                size="18"
                @click="delect(scope.row)"
              /> -->
              <span class="table-icon" @click="delect(scope.row)">
                <i class="el-icon-delete"></i>
              </span>
              <span class="table-icon" @click="edit(scope.row)">
                <i class="el-icon-edit-outline"></i>
              </span>
              <!-- <feather-icon
                class="mr-1"
                icon="Edit2Icon"
                style="margin-right:0;color:#3B4660"
                size="18"
                @click="edit(scope.row)"
              /> -->
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 页码 -->
      <pagination :tableInfo="pageParams" @changePage="getLegalList" />

    </div>
    <!-- table -->
    <creat-inface-dialog
      ref="creatInfaceDialogRef"
      @add="handleAdd"
      @update="handleUpdate"
    />

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="_batchdeleteItemSyncConfig"></deleteConfirmDialog>
  </div>
</template>
<script>
import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'
import creatInfaceDialog from "./dialog/creatInfaceDialog.vue";
import topbar from "@/components/ui-topbar/index.vue";
import pagination from '@/components/pagination'
import {
  getCodeList,
  systemInterfaceEnble,
  systemInterfaceDisable,
} from "@/libs/api/taskManger.js";
import modelUi from "../../taskManagement/taskManger/utils/modelUi.js";
import {
  parseResp,
  parseError,
} from "../../taskManagement/taskManger/utils/requestUtil.js";


export default {
  components: {
    topbar,
    creatInfaceDialog,
    pagination,
    deleteConfirmDialog
  },
  data() {
    return {
      leng: "",
      tableData: [],
      interfaceTypeList: [],
      stateList: [
        {
          dictValue: "",
          dictName: this.$t('interfaceConfig.all'),
        },
        {
          dictValue: 1,
          dictName: this.$t('interfaceConfig.enable'),
        },
        {
          dictValue: 0,
          dictName: this.$t('interfaceConfig.disable'),
        },
      ],
      pageParams: {
        status: "",
        current: 1,
        size: 10,
        total: 20,
        interfaceName: "",
        interfaceType: "",
        updateTime: "",
        orders: [],
      },
    };
  },
  created() {
    this.getLegalList();
    this.getCodeListReq();
  },
  methods: {
    handleSortChange(v1) {
      const { prop } = v1;
      const { order } = v1;
      let orderStr = "";
      if (order === "descending") {
        orderStr = "desc";
      }
      if (order === "ascending") {
        orderStr = "asc";
      }

      let keyName = "";
      if (prop === "interfaceName") {
        keyName = "interface_name";
      }
      if (prop === "updateTime") {
        keyName = "update_time";
      }

      if (orderStr) {
        const orders = [];
        const orderItem = `${orderStr}:${keyName}`;
        orders.push(orderItem);
        this.pageParams.orders = orders;
      } else {
        this.pageParams.orders = [];
      }
      this.getLegalList();
    },
    handleAdd() {
      this.pageParams.current = 1;
      this.getLegalList();
    },

    handleUpdate() {
      this.getLegalList();
    },

    // 新增
    newadd() {
      this.$refs.creatInfaceDialogRef.openDialog();
    },
    selection() {
      return this.$refs.tableRows && this.$refs.tableRows.selection.length;
    },
    // 修改
    edit(row) {
      const newRow = JSON.parse(JSON.stringify(row));
      this.$refs.creatInfaceDialogRef.openDialog(newRow);
    },
    // 批量删除
    alldelt() {
      let ids = this.$refs.tableRows.selection.map((res) => res.id);
      ids = ids.join(",");
      this.delteInfor = ids
      this.$refs.deleteConfirmDialog.open()
    },
    // 单个删除
    delect(data) {
      this.delteInfor = data.id
      this.$refs.deleteConfirmDialog.open()
    },
    // 批量逻辑删除
    async _batchdeleteItemSyncConfig() {
      let res = await this.$Api.batchdeleteItemSyncConfig({ids:this.delteInfor});
      if(res.state){
        this.$refs.deleteConfirmDialog.close()
        this.$message({
          type: "success",
          message: this.$t("commonMessage.DeleteSucess"),
        });
        this.getLegalList();
      } else {
        this.$refs.deleteConfirmDialog.close()
        this.$message.error(res.message)
      }
    },
    // 逻辑删除
    async _deleteItemSyncConfig(ids) {
      let res = await this.$Api.deleteItemSyncConfig(ids);
      if(res.state){
        this.$message({
          type: "success",
          message: this.$t("commonMessage.DeleteSucess"),
        });
      } else {
        this.$message.error(res.message)
      }
    },

    getLegalList() {
      this.getInterfaceListReq();
    },
    async getInterfaceListReq() {
      const data = {
        interfaceName: this.pageParams.interfaceName,
        interfaceType: this.pageParams.interfaceType,
        isEffect: "",
        status: this.pageParams.status,
        startDate: this.pageParams.time ? this.pageParams.time[0] : null,
        endDate: this.pageParams.time ? this.pageParams.time[1] : null,
        current: this.pageParams.current,
        size: this.pageParams.size,
        orders: this.pageParams.orders,
      };
      const res = await this.$Api.getInterfaceList(data);
      this.rows = [];
      this.leng = res.data.total;
      this.pageParams.total = res.data.total;
      this.tableData = res.data.records;
    },
    handleSelectAll() {
      const { isAllSelected } = this.$refs.tableRows.store.states;
      console.log(
        "selection==",
        this.$refs.tableRows.store.states.isAllSelected
      );
      if (isAllSelected) {
        const arr = [];
        selection.forEach((it) => {
          arr.push(...it.children);
        });
        this.selectChild(arr);
      } else {
        this.$refs.tableRows.clearSelection();
      }
    },
    // 时间格式化
    formatDate(row, column) {
      // 获取单元格数据
      const data = row[column.property];
      if (data == null) {
        return null;
      }
      const dt = new Date(data);
      return `${dt.getFullYear()}-${dt.getMonth()}-${dt.getDate()} ${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}`;
    },
    getCodeListReq() {
      getCodeList(23)
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            this.interfaceTypeList = result.data;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },
    stateChange(row) {
      let params = {
        id: row.id,
      };
      if (row.status == 0) {
        systemInterfaceDisable(params)
          .then((res) => {
            const result = parseResp(res);
            if (result.success) {
              this.interfaceTypeList = result.data;
            } else {
              modelUi.msgError(result.message);
            }
          })
          .catch((err) => {
            modelUi.msgError(parseError(err));
          });
      } else if (row.status == 1) {
        systemInterfaceEnble(params)
          .then((res) => {
            const result = parseResp(res);
            if (result.success) {
              this.interfaceTypeList = result.data;
            } else {
              modelUi.msgError(result.message);
            }
          })
          .catch((err) => {
            modelUi.msgError(parseError(err));
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.interface {
  .storelistrom {
    padding: 15px;
  }
  .handleBtn {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .hideClass {
    visibility: hidden;
  }
  .icon {
    padding: 6px;
    cursor: pointer;
    .svg-icon {
      width: 23px;
      height: 23px;
    }
  }
  .text {
    padding: 0 15px;
  }
  .table_set {
    //   padding: 15px 0;
    background-color: white;
    .select_filter {
      padding-bottom: 15px;
      .table_label_size {
        width: 160px;
        font-size: 16px;
      }
      .table_select {
        width: 214px;
      }
    }
    .icon {
      padding: 6px;
      cursor: pointer;
      .svg-icon {
        width: 23px;
        height: 23px;
      }
    }
    .tb_checkbox {
      vertical-align: middle;
    }
  }

  .el-date-editor.el-input__inner {
    width: 206px;
  }
  .table-icon {
    padding: 6px;
    cursor: pointer;
    font-size: 18px;
    color: #000;
  }
}
.interface ::v-deep {
  .el-table
    .el-table__header-wrapper
    .el-table__header
    .is-group
      tr:nth-of-type(2)
      th:nth-of-type(1)
      .cell {
      display: none;
    }
}
</style>
