<template>
  <div class="create-inface-dialog">
    <el-dialog
      :visible.sync="open"
      width="820px"
      :before-close="cancel"
      :close-on-click-modal="false"
      :title="
        isEdit
          ? $t('interfaceConfig.Edit Interface')
          : $t('interfaceConfig.Create Interface')
      "
    >
      <div class="dialog-common-container">
        <el-scrollbar style="height: 100%">
          <!-- <div slot="title">
            <span class="dialog-title">{{ dialogTitle }}</span>
          </div> -->
          <div>
            <el-form
              v-if="open"
              ref="form"
              :model="form"
              :rules="rules"
              label-width="145px"
              size="small"
            >
              <el-collapse v-model="activeNames">
                <el-collapse-item
                  :title="$t('interfaceConfig.Interface Definition')"
                  name="1"
                >
                  <el-form-item
                    :label="$t('interfaceConfig.Interface Name')"
                    prop="interfaceName"
                  >
                    <el-input v-model="form.interfaceName" placeholder="" clearable />
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.Interface Type')"
                    required
                  >
                    <el-radio
                      v-model="form.interfaceType"
                      :label="2301"
                      :disabled="isEdit"
                    >
                      {{ $t("interfaceConfig.Files") }}
                    </el-radio>
                    <el-radio
                      v-model="form.interfaceType"
                      :label="2302"
                      :disabled="isEdit"
                    >
                      {{ $t("interfaceConfig.View") }}
                    </el-radio>
                  </el-form-item>
                  <el-form-item :label="$t('interfaceConfig.Enable')">
                    <el-switch
                      v-model="form.status"
                      :active-value="1"
                      :inactive-value="0"
                    />
                  </el-form-item>
                </el-collapse-item>
                <el-collapse-item
                  v-if="form.interfaceType == 2301"
                  :title="$t('interfaceConfig.Reading Mode')"
                  name="2"
                >
                  <el-form-item :label="$t('interfaceConfig.Reading Mode')">
                    <el-select
                      v-model="form.syncMode"
                      placeholder=""
                      style="width: 100%"
                    >
                      <el-option
                        v-for="dict in readModeList"
                        :key="dict.code"
                        :label="dict.value"
                        :value="dict.code"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.Catalogue')"
                    prop="accessAddress"
                  >
                    <el-input
                      v-model="form.accessAddress"
                      suffix-icon="el-icon-folder-opened"
                    />
                  </el-form-item>
                  <el-form-item
                    v-if="form.syncMode == 2004"
                    :label="$t('interfaceConfig.User')"
                    prop="commonValidata"
                  >
                    <el-input v-model="form.accessAccount" clearable />
                  </el-form-item>
                  <el-form-item
                    v-if="form.syncMode == 2004"
                    :label="$t('interfaceConfig.Password')"
                    prop="commonValidata"
                  >
                    <el-input v-model="form.accessPassword" clearable show-password />
                  </el-form-item>
                </el-collapse-item>
                <!-- File Configuration -->
                <el-collapse-item
                  v-if="form.interfaceType == 2301"
                  :title="$t('interfaceConfig.File Configuration')"
                  name="3"
                >
                  <el-form-item
                    :label="$t('interfaceConfig.Scan Interval(m)')"
                    prop="scanInterval"
                  >
                    <el-input
                      v-model.number="form.scanInterval"
                      type="number"
                      @input="limitScanInterval"
                    />
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.File Name Format')"
                    prop="fileNameFormat"
                  >
                    <el-input v-model="form.fileNameFormat" />
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.File Format')"
                    required
                  >
                    <el-input v-model="form.fileFormat" :disabled="true" />
                  </el-form-item>
                </el-collapse-item>
                <!-- File Parsing -->
                <el-collapse-item
                  v-if="form.interfaceType == 2301"
                  :title="$t('interfaceConfig.File Parsing')"
                  name="4"
                >
                  <el-form-item
                    :label="$t('interfaceConfig.Start Line')"
                    prop="startReadLine"
                  >
                    <el-input v-model="form.startReadLine" />
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.Separator')"
                    prop="delimiter"
                  >
                    <el-select
                      v-model="form.delimiter"
                      style="width: 100%"
                      placeholder=""
                    >
                      <el-option
                        v-for="dict in SeparatorList"
                        :key="dict.code"
                        :label="dict.code"
                        :value="dict.code"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.Delete Read Files')"
                    required
                  >
                    <el-switch
                      v-model="form.deleteReadFile"
                      active-value="1"
                      inactive-value="0"
                    />
                  </el-form-item>
                </el-collapse-item>
                <!-- 类型 View Reading Mode -->
                <el-collapse-item
                  v-if="form.interfaceType == 2302"
                  :title="$t('interfaceConfig.Reading Mode')"
                  name="5"
                >
                  <el-form-item
                    :label="$t('interfaceConfig.Database Type')"
                    prop="accessDbType"
                  >
                    <el-select v-model="form.accessDbType" style="width: 100%">
                      <el-option
                        v-for="dict in databaseTypeList"
                        :key="dict.code"
                        :label="dict.value"
                        :value="dict.code"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.SQL')"
                    prop="accessSql"
                  >
                    <el-input v-model="form.accessSql" />
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.User Name')"
                    prop="accessAccount"
                  >
                    <el-input v-model="form.accessAccount" />
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.Password')"
                    prop="accessPassword"
                  >
                    <el-input v-model="form.accessPassword" show-password />
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.Address')"
                    prop="accessAddress"
                  >
                    <el-input v-model="form.accessAddress" />
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.Read Interval(m)')"
                    prop="scanInterval"
                  >
                    <el-input
                      v-model.number="form.scanInterval"
                      type="number"
                      @input="limitScanInterval"
                    />
                  </el-form-item>
                  <el-form-item
                    :label="$t('interfaceConfig.Separator')"
                    prop="delimiter"
                  >
                    <el-select v-model="form.delimiter" style="width: 200px">
                      <el-option
                        v-for="dict in SeparatorList"
                        :key="dict.code"
                        :label="dict.code"
                        :value="dict.code"
                      />
                    </el-select>
                  </el-form-item>
                </el-collapse-item>
                <!-- Data Mapping -->
                <el-collapse-item
                  :title="$t('interfaceConfig.Data Mapping')"
                  name="6"
                >
                  <el-form-item
                    :label="$t('interfaceConfig.Scenarios')"
                    prop="scenarioId"
                  >
                    <el-select
                      v-model="form.scenarioId"
                      style="width: 100%"
                      @change="readModeChange"
                      @visible-change="readModeVisibleChange"
                      placeholder=""
                    >
                      <el-option
                        v-for="dict in ScenariosList"
                        :key="dict.scenarioId"
                        :label="dict.translateTextName"
                        :value="dict.scenarioId"
                      />
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item
                    label="Date Format"
                    required
                  >
                    <el-input v-model="form.dateFormat" />
                  </el-form-item> -->
                  <el-form-item
                    v-if="form.interfaceType == 2301"
                    :label="$t('interfaceConfig.Target Store')"
                    prop="targetStore"
                  >
                    <div class="targetStore">
                      <div class="item">
                        <el-radio
                          v-model="form.targetStoreType"
                          :label="0"
                          @change="handleTargetStoreType"
                        >
                          {{ $t("interfaceConfig.CSV") }}
                        </el-radio>
                        <template v-if="!(form.targetStoreType === 2)">
                          <el-input
                            v-model="form.targetStore"
                            size="small"
                            style="width: 100px"
                            clearable
                            @input="targetStoreInputChange"
                            :disabled="!(form.targetStoreType === 0)"
                          />
                          <span style="margin-left: 10px">{{$t("interfaceConfig.Column")}}</span>
                        </template>
                      </div>
                      <div>
                        <el-radio
                          v-model="form.targetStoreType"
                          :label="2"
                          @change="handleTargetStoreType"
                        >
                          {{ $t("interfaceConfig.Store") }}
                        </el-radio>
                        <template v-if="form.targetStoreType === 2">
                          <el-button
                            plain
                            size="small"
                            icon="el-icon-house"
                            @click="switchStore"
                          >
                            {{$t("interfaceConfig.Select Store")}}
                            <!-- {{ form.targetStore ? "已选门店" : "选择门店" }} -->
                          </el-button>
                        </template>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item
                    v-if="form.interfaceType == 2302"
                    :label="$t('interfaceConfig.Target Store')"
                    required
                    prop="targetStore"
                  >
                    <div class="targetStore">
                      <div>
                        <el-radio v-model="form.targetStoreType" :label="1" @change="handleTargetStoreType">
                          {{ $t("interfaceConfig.Read by Table Field") }}
                        </el-radio>
                        <template v-if="form.targetStoreType === 1">
                          <el-input
                            v-model="form.targetStore"
                            size="small"
                            style="width: 100px"
                            clearable
                          />
                          <!-- <el-input
                            v-model="form.targetStore"
                            size="small"
                            style="width: 100px"
                            :min="1"
                            @input="targetStoreInputChange"
                          /> -->
                        </template> 
                      </div>
                      <div>
                        <el-radio v-model="form.targetStoreType" :label="2" @change="handleTargetStoreType">
                          {{ $t("interfaceConfig.Store") }}
                        </el-radio>
                        <template v-if="form.targetStoreType === 2">
                          <el-button
                            plain
                            size="small"
                            icon="el-icon-house"
                            @click="switchStore"
                          >
                            {{$t("interfaceConfig.Select Store")}}
                          </el-button>
                        </template>
                        <!-- <el-cascader
                          :options="TargetStoreList"
                          v-model="form.targetStore"
                          @visible-change="getStoreListChange"
                          style="width: 200px"
                          :props="{ multiple: true, checkStrictly: true ,children:'nextStoreList',value:'storeId',label:'storeName'}"
                          clearable
                        ></el-cascader>-->
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item
                    v-if="form.interfaceType == 2302"
                    :label="$t('interfaceConfig.Update Mode')"
                    required
                    prop="targetStore"
                  >
                    <div class="targetStore">
                      <div>
                        <el-radio v-model="form.updateMode" :label="0">
                          {{ $t("interfaceConfig.Full Update") }}
                        </el-radio>
                      </div>
                      <div>
                        <el-radio v-model="form.updateMode" :label="1">
                          {{ $t("interfaceConfig.Incremental Update") }}
                        </el-radio>
                        <el-input
                          v-model="form.updateTimeField"
                          size="small"
                          style="width: 150px"
                          clearable
                        />
                      </div>
                    </div>
                  </el-form-item>
                  
                  <div style="margin:0 -40px">
                    <el-table
                      :data="form.fieldMappingList"
                      border
                      style="width: 100%"
                      :header-cell-style="{ 'text-align': 'center' }"
                      :cell-style="{ 'text-align': 'center' }"
                      class="creatInfaceTable"
                      table-layout="fixed"
                    >
                      <el-table-column :label="$t('interfaceConfig.No')" width="50">
                        <template slot-scope="scope">
                          <div>{{ scope.row.fieldSeq }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        v-if="form.interfaceType == 2302"
                        :label="$t('interfaceConfig.Read Table Field')"
                      >
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.fieldName" size="small" />
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('interfaceConfig.Fleld Key')"
                        min-width="120"
                      >
                        <template slot-scope="scope">
                          <el-select
                            v-model="scope.row.scenarioFieldKey"
                            style="width: 100%"
                            size="small"
                          >
                            <el-option
                              v-for="dict in scenarioFieldKeyList"
                              :key="dict.fieldKey"
                              :label="dict.translateTextName"
                              :value="dict.fieldKey"
                            />
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('interfaceConfig.Read')"
                        min-width="80"
                      >
                        <template slot-scope="scope">
                          <el-checkbox
                            v-model="scope.row.isRead"
                            :true-label="1"
                            :false-label="0"
                          />
                          <!-- <el-switch v-model="form.isEffect" :active-value="0" :inactive-value="1"></el-switch> -->
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('interfaceConfig.Default Value')"
                        min-width="120"
                      >
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.defaultValue" size="small" />
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('interfaceConfig.Thousandth')"
                        width="150"
                      >
                        <template slot-scope="scope">
                          <el-select
                            v-model="scope.row.thousandth"
                            style="width: 129px"
                            size="small"
                          >
                            <el-option
                              v-for="dict in SeparatorList"
                              :key="dict.code"
                              :label="dict.code"
                              :value="dict.code"
                            />
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('interfaceConfig.Action')"
                        width="85"
                      >
                        <template slot-scope="scope">
                          <a
                            href="javascript:void(0)"
                            @click="
                              deleteTableRows(scope.$index, form.fieldMappingList)
                            "
                          >
                            <i class="el-icon-delete" />
                          </a>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-button
                      style="margin-top: 10px"
                      size="small"
                      @click="addTableRows"
                      >{{ $t("interfaceConfig.Add Row") }}</el-button
                    >
                  </div>

                </el-collapse-item>
              </el-collapse>
              
            </el-form>
          </div>
        </el-scrollbar>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">{{
          $t("button.Cancel")
        }}</el-button>
        <el-button size="small" type="success" @click="submitForm('form')">{{
          $t("button.Save")
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 选择门店弹窗 -->
    <selectStore
      ref="selectStore"
      :multiple="true"
      @confirmSelect="changeStoreCallback"
    ></selectStore>
  </div>
</template>

<script>
// import { codeSnow } from '@/views/forms/form-element/quill-editor/code'
import selectStore from "@/components/select-store/index";

import {
  creatItemSyncConfig,
  updateItemSyncConfig,
} from "@/libs/api/taskManger";

import {
  parseResp,
  parseError,
} from "@/views/taskManagement/taskManger/utils/requestUtil";

import modelUi from "@/views/taskManagement/taskManger/utils/modelUi";
import {
  getScenarioList,
  getStoreList,
  getScenariInfo,
} from "@/libs/api/Syconfiguration";

export default {
  components: {
    selectStore,
  },
  data() {
    const validFileNameFormats = (rule, value, callback) => {
      if (!value || value === "") {
        callback(new Error(this.$t('formValidate.required fields')));
      } else {
        const ruleString = value.substring(value.length - 4, value.length);
        const ruleString1 = value.length>=5? value.substring(value.length - 5, value.length):''
        
        console.log('ruleString',ruleString)
        if (
          ruleString == ".csv" ||
          ruleString == ".txt" ||
          ruleString == ".xls" ||
          ruleString1 == ".xlsx"
        ) {
          callback();
        } else {
          callback(new Error(this.$t('interfaceConfig.ErrorMessage1')));
        }
      }
    };
    return {
      activeNames: ["1", "2", "3", "4", "5","6"],
      dialogTitle: "",
      // 是否显示弹出层
      open: false,
      isEdit: false,
      loading: false,
      ScenariosList: [],
      TargetStoreList: [],
      scenarioFieldKeyList: [],
      readModeList: [
        {
          code: 2002,
          value: this.$t('interfaceConfig.File Catalogue'),
        },
        {
          code: 2004,
          value: this.$t('interfaceConfig.FTP Catalogue'),
        },
      ],
      SeparatorList: [
        {
          code: ",",
          value: "逗号",
        },
        {
          code: ";",
          value: "分号",
        },
        {
          code: ".",
          value: "点",
        },
        {
          code: "/",
          value: "斜杠",
        },
        {
          code: "|",
          value: "竖杠",
        },
      ],
      databaseTypeList: [
        {
          code: '2401',
          value: "Mysql",
        },
        {
          code: '2402',
          value: "Sqlserver",
        },
        {
          code: '2403',
          value: "Oracle",
        },
      ],
      // 表单参数
      form: {
        status: 1,
        isEffect: 0,
        fileFormat: "Text",
        interfaceType: 2301,
        deleteReadFile: "0",
        syncMode: 2002,
        accessDbType: "",
        fieldMappingList: [
          { fieldSeq: "1", isRead:1,scenarioFieldKey:'' },
          { fieldSeq: "2", isRead:1,scenarioFieldKey:''},
          { fieldSeq: "3", isRead:1,scenarioFieldKey:''},
        ],
        targetStore: "",
      },
      // 表单校验
      rules: {
        interfaceName: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
          {
            min: 1,
            max: 100,
            message: this.$t("formValidate.maxLength"),
            trigger: "blur",
          },
        ],
        catalogue: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
        scanInterval: [
          { required: true, message: this.$t("formValidate.required fields"), trigger: "blur",},
          // {
          //   required: true,
          //   type: "number",
          //   message: "长度最多16位",
          //   trigger: "blur",
          // },
        ],
        accessDbType: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "change",
          },
        ],
        delimiter: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "change",
          },
        ],
        scenarioId: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "change",
          },
        ],
        accessSql: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
        startReadLine: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
        accessAddress: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
        accessPassword: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
        accessAccount: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
        fileNameFormat: [
          { required: true, validator: validFileNameFormats, trigger: "blur" },
        ],
        targetStore:[
          { required: true, message: this.$t("formValidate.required fields"), trigger: "blur" },
        ]
      },
    };
  },
  methods: {
    targetStoreInputChange(value) {
      let value1 = value.replace(/[^\d]/g, "");
      console.log("---targetStoreInputChange", value1);
      if (value1 <= 0) {
        value1 = "";
      }
      this.form.targetStore = value1;
    },
    switchStore() {
      this.$refs.selectStore.open(this.form.targetStore);
      // this.$refs.changeStoreDialogRef.openchangeStoreDialog(
      //   this.form.targetStore
      // );
    },
    openDialog(row) {
      console.log('row',row)
      if (row) {
        this.dialogTitle = "Edit Interface";
        row.fieldMappingList = JSON.parse(row.fieldMapping);
        row.accessSql = decodeURIComponent(row.accessSql)
        this.form = row;
        this.form.fileFormat = "Text";
        this.isEdit = true;
        const params = {
          scenarioId: this.form.scenarioId,
        };
        this._getScenariInfo(params);
        this.readModeVisibleChange();
      } else {
        this.form = {
          status: 1,
          isEffect: 0,
          fileFormat: "Text",
          interfaceType: 2301,
          deleteReadFile: "0",
          syncMode: 2002,
          fieldMappingList: [
            { fieldSeq: "1", isRead:1, scenarioFieldKey:''},
            { fieldSeq: "2", isRead:1, scenarioFieldKey:''},
            { fieldSeq: "3", isRead:1, scenarioFieldKey:''},
          ],
        };
        this.isEdit = false;
        this.dialogTitle = "Create Interface";
      }
      this.open = true;
    },

    // 取消按钮
    cancel() {
      this.isEdit = false;
      this.form = {
        status: 0,
        isEffect: 0,
        fileFormat: "Text",
        interfaceType: 2301,
        deleteReadFile: "0",
        syncMode: 2002,
        fieldMappingList: [
          { fieldSeq: "1", isRead:1, scenarioFieldKey:''},
          { fieldSeq: "2", isRead:1, scenarioFieldKey:''},
          { fieldSeq: "3", isRead:1, scenarioFieldKey:''},
        ],
      };
      this.open = false;
    },

    // 提交按钮
    submitForm(formName) {
      this.activeNames = ["1", "2", "3", "4", "5","6"];
      // this.form
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            this.updateItemSyncConfigReq();
          } else {
            this.creatItemSyncConfigReq();
          }
        } else {
          console.log("error submit!!");
          console.log(this.form);
          return false;
        }
      });
    },
    readModeChange(data) {
      const params = {
        scenarioId: data,
      };
      this._getScenariInfo(params);
    },
    readModeVisibleChange() {
      this.getScenarioList();
    },
    async getScenarioList() {
      const data = {
        scenarioKey: "",
        isEffect: 0,
        agentId: "",
      };
      const res = await this.$Api.getScenarioSimplifyList(data);
      this.ScenariosList = res.data;
    },
    getStoreListChange() {
      const data = {
        storeId: sessionStorage.getItem("storeId") || 1,
      };
      getStoreList(data).then((res) => {
        if (res.data && res.data.data) {
          this.TargetStoreList = [];
          this.TargetStoreList.push(res.data.data);
        }
      });
    },
    // 删除表格行
    deleteTableRows(index, rows) {
      this.$confirm("确定要删除此行吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          rows.splice(index, 1);
          this.form.fieldMappingList.forEach(function (ele, index) {
            ele.fieldSeq = index + 1;
            ele.isRead = 1
          });
          this.$message({
            type: "success",
            message: this.$t("commonMessage.DeleteSucess"),
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 添加表格行
    addTableRows() {
      this.form.fieldMappingList.push({
        fieldSeq: this.form.fieldMappingList.length + 1,
        isRead:1,
        scenarioFieldKey:''
      });
      this.form.fieldMappingList.forEach(function (ele, index) {
        ele.fieldSeq = index + 1;
      });
    },
    creatItemSyncConfigReq() {
      let formData = this.form
      if(formData.accessSql){
        formData.accessSql = encodeURIComponent(formData.accessSql)
      }
      creatItemSyncConfig(formData)
        .then((res) => {
          this.loading = false;
          const result = parseResp(res);
          if (result.success) {
            this.open = false;
            this.$message({
              message: this.$t("commonMessage.CreateSucess"),
              type: "success",
            });
            this.$emit("add");
          } else {
            this.$message.error(result.message)
            // modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          modelUi.msgError(parseError(err));
        });
    },
    updateItemSyncConfigReq() {
      let formData = this.form
      if(formData.accessSql){
        formData.accessSql = encodeURIComponent(formData.accessSql)
      }
      updateItemSyncConfig(formData)
        .then((res) => {
          this.loading = false;
          const result = parseResp(res);
          if (result.success) {
            this.open = false;
            this.$message({
              message: this.$t("commonMessage.ModifySucess"),
              type: "success",
            });
            this.$emit("update");
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          this.loading = false;
          modelUi.msgError(parseError(err));
        });
    },
    _getScenariInfo(params) {
      this.getScenariInfo(params);
    },
    async getScenariInfo(params) {
      const res = await this.$Api.getScenariInfo(params);
      this.scenarioFieldKeyList = res.data.fieldList;
    },
    limitScanInterval(value) {
      if (value.toString().length > 16) {
        this.form.scanInterval = this.form.scanInterval.toString().slice(0, 15);
      }
    },
    // 门店选择
    // changeStoreCallback(ids) {
    //   this.form.targetStore = ids;
    // },
    changeStoreCallback(arr) {
      console.log(arr);
      let ids = arr.map((item) => item.storeId).join(",");
      this.form.targetStore = ids;
    },
    // 目标门店 ---change
    handleTargetStoreType(val) {
      console.log("--目标门店 ---change--", val);
      let data = {
        ...this.form,
        targetStore:''
      }
      this.form = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.create-inface-dialog {
  .dialog-common-container {
    height: calc(60vh);
  }

  .creatInfaceTable .el-table__cell {
    border-right: 1px solid #ebeef5 !important;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  .el-collapse {
    border: none;
  }
  .dark-layout label {
    color: #71757c;
    font-size: 13px;
  }
  .targetStore {
    height: 70px;
    .item {
      margin-bottom: 10px;
    }
  }

  // 重置样式
  ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  ::v-deep .el-dialog__body {
    padding: 20px !important;
  }
  .el-dialog__footer {
    padding-bottom: 20px;
  }
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    padding-bottom: 40px;
  }
  ::v-deep .el-collapse-item__wrap {
    border-bottom: none;
  }
  ::v-deep .el-collapse-item__content {
    padding: 20px 40px 0;
  }
  ::v-deep .el-collapse-item {
    margin-bottom: 20px;
  }
  ::v-deep .el-collapse-item__header {
    height: 30px;
    line-height: 30px;
    background-color: #f3f3f3;
    border: none;
    border-radius: 4px;
    padding: 20px;
    font-size: 14px;
  }
  ::v-deep .el-button {
    min-width: 110px;
  }
}
</style>
