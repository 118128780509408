import router from "../axios";
import config from "../../config/index"
const { baseUrl } = config;

function Unitsect(data) {
    //用户组更新
    return router({
        url: baseUrl + "unit/v1/getList",
        params: data,
        method: "POST",
    });
}
//接口管理====
//获取接口列表
function getInterfaceList(data) {
    return router({
        url: baseUrl + "item/sync/config/v1/getList",
        params: data,
        method: "GET"
    })
}
// 获取场景
function getScenarioList(data) {
    return router({
        url: baseUrl + "scenario/v1/getScenarioList",
        params: data,
        method: "GET"
    })
}
// 获取场景详情 scenario/v1/getScenario
function getScenariInfo(data) {
    return router({
        url: baseUrl + "scenario/v1/getScenario",
        params: data,
        method: "GET"
    })
}
// 获取门店列表
function getStoreList(data) {
    return router({
        url: baseUrl + "store/v1/switchPage",
        params: data,
        method: "GET"
    })
}
//创建配置item/sync/config/v1/create
function creatItemSyncConfig(data) {
    return router({
        url: baseUrl + "item/sync/config/v1/create",
        params: data,
        method: "POST"
    })
}
export {
    Unitsect,
    getInterfaceList,
    getScenarioList,
    getStoreList,
    getScenariInfo,
    creatItemSyncConfig
};